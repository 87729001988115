
<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authHeader } from '../../../helpers/fakebackend/auth-header';

import {
  required 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Addons",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Create AddOn Group",
      items: [
        {
          text: "Addons",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      addonItemData: [
        {
          id: 1,
          addonItemID:0,
          itemTitle: "",
          price: "",
          isActive: 0,
        },
      ],
      restaurants:[],
      companyID:0,
      status :'',
      submitted: false,
      foodTypeArr:[],
      itemTitle:"",
      typeID:'',
      price:'',
      showDismissibleAlert :false,
      loading: false,
      addonGroupID:0,
      isEdit: false,
      isActive: true,
      addonData:[],
      posts:{
        groupName:null,
        restaurantID:null,
        foodType: [],
        value: '',
        sku:"",
      }
    };
  },

  validations: {
    posts: {
      restaurantID: {
        required,
      },
      groupName: {
        required,
      },
      sku: {
        required,
      },
    },
  },
  methods:{
    submitAddonForm(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
          this.loading = true;
          let formData = new FormData();  

          formData.append('addonGroupID',this.addonGroupID);
          formData.append('restaurantID', this.posts.restaurantID.restID);
          formData.append('groupName', this.posts.groupName);
          formData.append('sku', this.posts.sku);
          formData.append('addonItemData', JSON.stringify(this.addonItemData));
          formData.append('createdBy', this.$storageData.profile.pid);
          formData.append('loginTypeID',this.$storageData.login_type);
         
          let apiName = this.$loggedRole+"/addAddon";
          if(this.isEdit==true){
              apiName = this.$loggedRole+"/updateAddon";
          }

          this.axios.post(apiName,  formData, {
            headers: authHeader()
          })
          .then((result)=>{
            this.status = result.data.data;
            //this.$router.go();
        
            this.showDismissibleAlert = true;
            this.loading = false;

            setTimeout(() => {
                this.$router.push({name: 'addonsTables'});  
            },2000);
          })
          .catch(function (error) {
              this.loading = false;
              console.error(error.response);
              alert("Something went Wrong");
          });
      }  
    },
    getRestaurantList(){
      var companyID = this.companyID;
      if(this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 11)
      {
        companyID = this.$storageData.profile.companyID;
      }
       
      this.axios.post(this.$loggedRole+"/getRestaurantList", {
        companyID: companyID,
        'restID': this.$storageData.login_type == 2 && this.$storageData.profile.empTypeID != 11 ? this.$storageData.profile.restID : 0,
        'restBranchID': this.$storageData.login_type == 2 && this.$storageData.profile.empTypeID != 11 ? this.$storageData.profile.restBranchID : 0,
        'empID': this.$storageData.profile.pid,
        'empTypeID': this.$storageData.profile.empTypeID,
        'city': this.$storageData.profile.city
      })
      .then((result)=>{
        this.restaurants = result.data.data;              
      });
    },
    foodTypeList(){
        this.axios.get(this.$loggedRole+"/getFoodTypeList")
        .then((result)=>{
            this.foodTypeArr = result.data.data;              
        });
    },
       
    addItem() {
      this.addonItemData.push({
        itemTitle: "",
        price: "",
        addonItemID: 0,
        isActive: 0,
      });
    },
    /**
     * Delete the row from form
     */
    deleteItem(index,addonItemID) {
      if(addonItemID>0){
        if (confirm("Are you sure you want to delete this element?")){
          this.axios.post(this.$loggedRole+"/deleteAddonItems",
            {'addonItemID':addonItemID})
          .then(()=>{
            this.addonItemData.splice(index, 1);                         
          });
        }
      }else{
        this.addonItemData.splice(index, 1);
      }
    },

    getAddonGroupByID(){
      this.axios.post(this.$loggedRole+"/getAddonGroupByID",
        {'addonGroupID':this.addonGroupID})
      .then((result)=>{
        this.addonData = result.data.data;
        this.posts.groupName = this.addonData.groupName;
        this.posts.sku = this.addonData.SKU;
        this.addonItemData = this.addonData.addonItems;
        this.posts.restaurantID = this.addonData.restaurantData;
        this.posts.isActive = this.addonData.addonItems.isActive;
      });
    },
  },

  mounted(){
    this.getRestaurantList();
    this.foodTypeList();
    
    if(this.$route.params.type=="edit"){
      if(this.$route.params.id){
        sessionStorage.setItem('addonGroupID',this.$route.params.id);
      } 
    }else{
      sessionStorage.setItem('addonGroupID',0);
    }
    
    this.addonGroupID = sessionStorage.getItem('addonGroupID')
    
    if(this.addonGroupID>0){
      this.getAddonGroupByID(this.addonGroupID);
      this.isEdit = true;     
    }
  }, 

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert"  variant="success" dismissible>
        Addons Added Successfully!
      </b-alert>
   </div>
  
  <form @submit.prevent="submitAddonForm" method="post">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12 mb-3">
            <label>Choose Restaurant Brand* </label>
            <multiselect v-model="posts.restaurantID" :options="restaurants" track-by="restID" label="name" 
              :class="{
                'is-invalid': submitted && $v.posts.restaurantID.$error,
            }" ></multiselect>
            <div v-if="submitted && $v.posts.restaurantID.$error" class="invalid-feedback">
              <span v-if="!$v.posts.restaurantID.required">This value is required.</span>
            </div>
          </div>
          <div class="mb-3 col-md-12">
            <label class="form-label" for="formrow-groupName-input">AddOn Group Name *</label>
            <input type="text" class="form-control" id="formrow-groupName-input" name="groupName" placeholder="Enter Name" v-model="posts.groupName"
              :class="{'is-invalid': submitted && $v.posts.groupName.$error,}" >
            <div v-if="submitted && $v.posts.groupName.$error" class="invalid-feedback">
              <span v-if="!$v.posts.groupName.required">This value is required.</span>
            </div>
          </div>
          <div class="mb-3 col-md-12">
            <label class="form-label" for="formrow-sku-input">SKU *</label>
            <input type="text" class="form-control" id="formrow-sku-input" name="sku"  
              placeholder="Enter the unique brand short code for addon group" 
              v-model="posts.sku"
              :class="{'is-invalid': submitted && $v.posts.sku.$error,}" >
            <div v-if="submitted && $v.posts.sku.$error" class="invalid-feedback">
              <span v-if="!$v.posts.sku.required">This value is required.</span>
            </div>
          </div>
          <h6 class="mb-3">Add AddOn Items:</h6>
          <div class="inner-repeater mb-4">
            <div class="inner mb-3">
              <div class="row">
                <label class="col-md-4">Item title :</label>
                <label class="col-md-3">Food Type:</label>
                <label class="col-md-3">Price:</label>
              </div>
              <div v-for="(data, index) in addonItemData" :key="data.id" class="inner mb-3 row">
                <div class="col-md-4 col-8">
                  <input v-model="data.itemTitle" type="text" class="inner form-control" placeholder="Enter addon title..."/>
                </div>
                <div class="col-md-3 col-8">
                  <multiselect v-model="data.typeID" :options="foodTypeArr" track-by="typeID" label="foodTypeName"></multiselect>
                </div>
                <div class="col-md-3 col-8">
                  <input v-model="data.price" type="text" class="inner form-control" placeholder="Enter addon price"/>
                </div>
                <div class="col-lg-1 align-self-center d-grid">
                  <input type="button" class="btn btn-themeBrown btn-block inner" value="Delete" @click="deleteItem(index,data.addonItemID)"/>
                </div>
                <div class="col-lg-1">
                  <div class="form-check form-switch form-switch-md mb-3" dir="ltr">
                    <input type="checkbox" class="form-check-input" id="customSwitchsizemd" v-model="data.isActive">        
                  </div>
                </div>
              </div>
            </div>
            <input type="button" class="btn btn-themeOrange inner" value="Add new item" @click="addItem"/>
          </div>

          <div class =" row" style="float:right;">
            <b-spinner v-if="loading" class="m-2 col-3" variant="primary" role="status"></b-spinner>        
            <button type="button" @click="submitAddonForm()" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">
              Submit
            </button>
          </div>
        </div>       
      </div>
    </div>

    <!-- <button class="btn btn-themeOrange" type="submit" style="position: fixed; bottom: 6px; right: 6px;border-radius: 50%; padding: 19px;"><i class="fas fa-check fa-2x"></i></button> -->
  </form>
  <!-- Success circle button with ripple effect -->
</Layout>
</template>
<style>
.alertMsg{
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
</style>


